<template>
  <ApolloQuery
    :query="require('@/graphql/queries/managerSupportRequests.gql')"
  >
    <template slot-scope="{ result: { loading, error, data } }">
      <div v-if="loading" class="messages__loading">Идёт загрузка данных...</div>
      <div v-if="error" class="messages apollo error">{{ error.message }}</div>
      <div v-if="!loading && !error" class="lsg-faq">
        <div class="main__header">
          <div class="main__header__item left"></div>
          <div class="main__header__item">
            <h1>{{ title }}</h1>
          </div>
          <div class="main__header__item right"></div>
        </div>
        <div class="main__content main__content--appeal">
          <div class="filter-by-answers">
            <SwitcherAlt :value="noAnswer" @updateTable="testChecked" />
          </div>
          <div :class="{'main__appeals--full': $store.state.isEmpty}" class="main__appeals">
            <vue-good-table
              max-height="600px"
              :columns="columns"
              :rows="transformData(data ? data.managerSupportRequests : [])"
              @on-row-click="onRowClick"
              compactMode
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'theme'" class="truncate-text">{{ props.row.theme }}</span>
                <span v-else-if="props.column.field == 'status' && props.row.status == 'Закрыт'">
                  <span class="tag">{{props.row.status}}</span>
                </span>
                <span v-else-if="props.column.field == 'status' && props.row.status == 'Открыт'">
                  <span class="tag tag--green">{{props.row.status}}</span>
                </span>
                <span v-else-if="props.column.field == 'isNoAnswered' && !props.row.isNoAnswered">
                  <span class="tag tag--orange">Да</span>
                </span>
                <span v-else-if="props.column.field == 'isNoAnswered' && props.row.isNoAnswered">
                  <span></span>
                </span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
              <div slot="emptystate" style="display: flex; justify-content: center;">
                Вам ещё не поступили обращения
              </div>
            </vue-good-table>
          </div>
          <div v-if="$store.state.isEmpty === false" class="main__request">
            <SupportRequest
              v-for="(item, index) in [selectedRequest]"
              :key="index"
              :id="item.id"
              :client="item.client_id"
              :clientName="item.client_name"
              :questionAndAnswer="item.qa_list"
              :registrationDate="item.created_date"
              :lastDate="item.last_date"
              :status="item.status"
              :theme="item.theme.name"
              :role="'manager'"
              :clientRole="item.role"
              :companyName="item.company_name"
              :companyId="item.company_id"
            />
          </div>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import SwitcherAlt from '@/components/ui/SwitcherAlt'
import SupportRequest from '@/components/ui/SupportRequest'
import moment from 'moment'

export default {
  name: 'Appeals',
  components: {
    SupportRequest,
    SwitcherAlt
  },
  beforeCreate () {
    this.$store.state.isEmpty = true
  },
  data () {
    return {
      title: 'Обращения',
      noAnswer: false,
      columns: [
        {
          label: 'Приоритет',
          field: 'priority',
          type: 'number',
          width: '120px'
        },
        {
          label: 'Наименование',
          field: 'name',
          width: '150px'
        },
        {
          label: 'Тема',
          field: 'themeName',
          width: '150px'
        },
        {
          label: 'Дата создания',
          field: 'created_date',
          width: '150px'
        },
        {
          label: 'Крайний срок',
          field: 'last_date',
          width: '150px'
        },
        {
          label: 'Статус',
          field: 'status',
          width: '150px'
        },
        {
          label: 'Без ответа',
          field: 'isNoAnswered',
          width: '150px'
        }
      ],
      sortedRequest: [],
      newSortedRequest: [],
      selectedRequest: {},
      qa: [],
      sortedQa: [],
      isEmpty: true
    }
  },
  methods: {
    testChecked (isCheck) {
      this.noAnswer = isCheck
    },
    formatDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY HH:MM') : ''
    },
    transformData (value) {
      if (value.length !== 0) {
        value.forEach(element => {
          element.name = element.company_name || element.client_name
          element.priority = element.theme.priority
          element.qa_list = element.questions
          element.lastQuestion = element.qa_list[element.qa_list.length - 1].question
          element.created_date = element.created_date.split('T')[0].split('-').reverse().join('.')
          element.last_date = element.last_date.split('T')[0].split('-').reverse().join('.')
          element.themeName = element.theme.name
          element.isNoAnswered = !!element.qa_list[element.qa_list.length - 1].answer
        })
      }
      if (this.noAnswer) {
        const noAnswerArr = []
        value.forEach(element => {
          if (!element.qa_list[element.qa_list.length - 1].answer) {
            noAnswerArr.push(element)
          }
        })
        return noAnswerArr
      }
      this.trasformedRequest = value
      return value
    },
    onRowClick (event) {
      this.trasformedRequest.forEach((element) => {
        if (element.id === event.row.id) {
          this.selectedRequest = element
        }
      })
      this.$store.state.isEmpty = false
    }
  }
}
</script>

<style lang="stylus">

.filter-by-answers {
  position absolute
  top -50px
  right 40px
}

.truncate-text {
  overflow hidden
  display -webkit-box
  -webkit-line-clamp 3
  -webkit-box-orient vertical
  line-height 1.3em
  height 3.9em
}
.main {
  &__content--appeal {
    display flex
    flex-direction row
    position relative

    +below(1150px) {
      flex-direction column-reverse
    }
  }

  &__appeals {
    transition width 1s
    width 50%

    &--full {
      transition width 1s
      width 100%
    }
  }
  &__request {
    width 50%
    padding 0 20px
  }
  +below(1150px) {
    &__appeals {
      width 100%
    }
    &__request {
      width 100%
    }
  }
}

</style>
